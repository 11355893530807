import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconFilter = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m14.689 3.2612c-1.3613 0-2.4648 1.1035-2.4648 2.4648s1.1035 2.4648 2.4648 2.4648c1.1282 0 2.0794-0.75803 2.372-1.7926h3.0057c0.37124 0 0.67221-0.30096 0.67221-0.67221 0-0.37126-0.30097-0.67221-0.67221-0.67221h-3.0057c-0.29264-1.0345-1.2438-1.7926-2.372-1.7926zm1.1204 2.4648c0-0.61876-0.50156-1.1204-1.1204-1.1204-0.6188 0-1.1204 0.5016-1.1204 1.1204 0 0.61875 0.50156 1.1204 1.1204 1.1204 0.61879 0 1.1204-0.50161 1.1204-1.1204zm-11.876-0.67221c-0.37125 0-0.67221 0.30096-0.67221 0.67221 0 0.37125 0.30096 0.67221 0.67221 0.67221h6.274c0.37124 0 0.67221-0.30096 0.67221-0.67221 0-0.37125-0.30097-0.67221-0.67221-0.67221zm0 12.548c-0.37125 0-0.67221 0.30097-0.67221 0.67221 0 0.37124 0.30096 0.67221 0.67221 0.67221h6.274c0.37124 0 0.67221-0.30097 0.67221-0.67221 0-0.37124-0.30097-0.67221-0.67221-0.67221zm10.755-1.7926c1.1282 0 2.0794 0.75799 2.372 1.7926h3.0057c0.37124 0 0.67221 0.30097 0.67221 0.67221 0 0.37124-0.30097 0.67221-0.67221 0.67221h-3.0057c-0.29264 1.0346-1.2438 1.7926-2.372 1.7926-1.3613 0-2.4648-1.1035-2.4648-2.4648 0-1.3613 1.1035-2.4648 2.4648-2.4648zm0 3.5851c0.61879 0 1.1204-0.50156 1.1204-1.1204 0-0.61879-0.50156-1.1204-1.1204-1.1204-0.6188 0-1.1204 0.50156-1.1204 1.1204 0 0.61879 0.50156 1.1204 1.1204 1.1204zm-11.428-7.3943c0-0.37124 0.30096-0.67221 0.67221-0.67221h3.0057c0.2926-1.0346 1.2438-1.7926 2.372-1.7926 1.3613 0 2.4648 1.1035 2.4648 2.4648s-1.1035 2.4648-2.4648 2.4648c-1.1282 0-2.0794-0.75799-2.372-1.7926h-3.0057c-0.37125 0-0.67221-0.30097-0.67221-0.67221zm9.8591 0c0-0.37124 0.30097-0.67221 0.67221-0.67221h6.274c0.37124 0 0.67221 0.30097 0.67221 0.67221s-0.30097 0.67221-0.67221 0.67221h-6.274c-0.37124 0-0.67221-0.30097-0.67221-0.67221zm-4.9296 0c0-0.61879 0.5016-1.1204 1.1204-1.1204 0.61876 0 1.1204 0.50156 1.1204 1.1204s-0.5016 1.1204-1.1204 1.1204c-0.61875 0-1.1204-0.50156-1.1204-1.1204z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth=".89628"
    />
  </IconBase>
)

export default IconFilter

import { atom, useRecoilState } from 'recoil'
import { useState } from 'react'
import { MAX_GUESTS, MIN_GUESTS } from '../utils/constants'

const recoilLastSearchedGroupSize = atom<number>({
  key: 'SelectedGroupSize',
  default: MIN_GUESTS,
})

const usePreviewGroupSize = (min = MIN_GUESTS, max = MAX_GUESTS) => {
  const [lastSearchedGroupSize, setLastSearchedGroupSize] = useRecoilState(
    recoilLastSearchedGroupSize
  )

  let closestAllowedGroupSize = lastSearchedGroupSize
  if (lastSearchedGroupSize < min) {
    closestAllowedGroupSize = min
  } else if (lastSearchedGroupSize > max) {
    closestAllowedGroupSize = max
  }

  const [previewGroupSize, _setPreviewGroupSize] = useState(closestAllowedGroupSize)

  const isMinReached = previewGroupSize <= min
  const isMaxReached = previewGroupSize >= max

  const increasePreviewGroupSize = () => {
    _setPreviewGroupSize((currVal) => {
      if (!currVal || currVal + 1 > max) {
        return max
      }

      return Math.max(currVal, min) + 1
    })
  }

  const decreasePreviewGroupSize = () => {
    _setPreviewGroupSize((currVal) => {
      if (!currVal || currVal - 1 < min) {
        return min
      }
      return Math.min(currVal, max) - 1
    })
  }

  const setPreviewGroupSize = (value: number) => {
    if (value <= min) {
      _setPreviewGroupSize(min)
      return
    }

    if (value >= max) {
      _setPreviewGroupSize(max)
      return
    }

    _setPreviewGroupSize(value)
  }

  return {
    setLastSearchedGroupSize,
    lastSearchedGroupSize,

    previewGroupSize,
    setPreviewGroupSize,
    increasePreviewGroupSize,
    decreasePreviewGroupSize,

    isMinReached,
    isMaxReached,
  }
}

export default usePreviewGroupSize

import React, { useMemo, useRef } from 'react'
import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import ListboxButton from '../Listbox/ListboxButton'
import ListboxOptions from '../Listbox/ListboxOptions'
import ListboxOption from '../Listbox/ListboxOption'

export interface Item {
  id: string
  label: string
  description?: string
}

interface Props {
  wildcardLabel?: string
  items: Item[]
  selectedItems: Item[]
  hint?: string
  labelClassName?: string
  onSelectedItemsChange: (selectedItems: Item[]) => void
  onClose?: () => void
  checkType?: 'icon' | 'checkbox'
  cy?: string
  onOpen?: () => void
}

const FilterSelect: React.FC<Props> = ({
  wildcardLabel = 'Anything',
  items,
  selectedItems,
  hint = undefined,
  labelClassName = undefined,
  onSelectedItemsChange,
  onClose = () => {},
  checkType = 'icon',
  cy = undefined,
  onOpen = () => {},
}) => {
  const handleInput = (updatedItems: Item[]) => {
    if (updatedItems.findIndex((i: Item) => i.id === 'wildcard') > -1 && selectedItems.length > 0) {
      // the wildcard item was newly selected
      return onSelectedItemsChange([])
    }

    return onSelectedItemsChange(updatedItems.filter(({ id }) => id !== 'wildcard'))
  }

  const wildcardItem = useMemo(() => ({ id: 'wildcard', label: wildcardLabel }), [wildcardLabel])

  const computedItems: Item[] = useMemo(() => [wildcardItem, ...items], [wildcardItem, items])

  const computedValue = useMemo(
    () => (selectedItems.length > 0 ? selectedItems : [wildcardItem]),
    [selectedItems, wildcardItem]
  )

  const previousStatus = useRef(false)

  const updateStatus = (newStatus: boolean) => {
    if (previousStatus.current !== newStatus) {
      if (newStatus) {
        onOpen()
      } else {
        onClose()
      }
    }
    previousStatus.current = newStatus
  }

  const preview = useMemo(() => {
    if (selectedItems.length < 1) {
      return wildcardLabel
    }

    return selectedItems
      .map((item) => item.label)
      .sort()
      .join(', ')
  }, [wildcardLabel, selectedItems])

  return (
    <Listbox
      as="div"
      className="relative min-w-0"
      value={computedValue}
      onChange={handleInput}
      data-cy={cy ? `${cy}-box` : undefined}
      multiple
    >
      {({ open }) => {
        updateStatus(open)

        return (
          <>
            <ListboxButton open={open} cy={cy ? `${cy}-button` : undefined}>
              {preview}
            </ListboxButton>
            <ListboxOptions>
              {computedItems.map((item) => (
                <ListboxOption
                  key={item.id}
                  value={item}
                  checkType={checkType}
                  cy={cy ? `${cy}-item` : undefined}
                >
                  {item.label && (
                    <div className={clsx('inline-flex', labelClassName)}>{item.label}</div>
                  )}
                  {item.description && <span className="ml-2">{item.description}</span>}
                </ListboxOption>
              ))}
              {hint && <li className="px-4 py-2 text-xs text-gray-500">{hint}</li>}
            </ListboxOptions>
          </>
        )
      }}
    </Listbox>
  )
}

export default FilterSelect

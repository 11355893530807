import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconUsers = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m5.9583 7.8333c0-1.4958 1.2126-2.7083 2.7083-2.7083 1.4958 0 2.7083 1.2126 2.7083 2.7083s-1.2126 2.7083-2.7083 2.7083c-1.4958 0-2.7083-1.2126-2.7083-2.7083zm2.7083-3.9583c-2.1861 0-3.9583 1.7722-3.9583 3.9583 0 2.1861 1.7722 3.9583 3.9583 3.9583 2.1861 0 3.9583-1.7722 3.9583-3.9583 0-2.1861-1.7722-3.9583-3.9583-3.9583zm5 0c-0.3452 0-0.625 0.27982-0.625 0.625s0.2798 0.625 0.625 0.625c1.4958 0 2.7083 1.2126 2.7083 2.7083s-1.2125 2.7083-2.7083 2.7083c-0.3452 0-0.625 0.27982-0.625 0.625 0 0.34517 0.2798 0.625 0.625 0.625 2.1861 0 3.9583-1.7722 3.9583-3.9583 0-2.1861-1.7722-3.9583-3.9583-3.9583zm-6.6666 11.25c-1.4958 0-2.7083 1.2126-2.7083 2.7083 0 0.5753 0.46637 1.0417 1.0417 1.0417h6.6667c0.57525 0 1.0416-0.4664 1.0416-1.0417 0-1.4957-1.2126-2.7083-2.7083-2.7083zm-3.9583 2.7083c0-2.1861 1.7722-3.9583 3.9583-3.9583h3.3333c2.1861 0 3.9583 1.7722 3.9583 3.9583 0 1.2657-1.026 2.2917-2.2916 2.2917h-6.6667c-1.2656 0-2.2917-1.026-2.2917-2.2917zm12.292-3.9583c-0.3452 0-0.625 0.2798-0.625 0.625s0.2798 0.625 0.625 0.625h1.6666c1.4958 0 2.7084 1.2126 2.7084 2.7083 0 0.5753-0.4664 1.0417-1.0417 1.0417h-3.3333c-0.3452 0-0.625 0.2798-0.625 0.625s0.2798 0.625 0.625 0.625h3.3333c1.2657 0 2.2917-1.026 2.2917-2.2917 0-2.1861-1.7722-3.9583-3.9584-3.9583z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </IconBase>
)

export default IconUsers
